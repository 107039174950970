<template>
  <mobile-screen :header="true" screen-class="profile-screen gray-bg icon-app1">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName,
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <template v-slot:center>
          <home-button />
        </template>
        <div class="component-title">
          {{ displayLabelName("visitor-management.reception-desks.import") }}
        </div>
      </top-header-menu-wrapper>
    </template>
    <div class="clebex-photo-selection-wrapper">
      <file-selection
        ref="fileSelection"
        :upload="uploadFile"
        :download="downloadExampleFile"
      ></file-selection>
    </div>
    <import-visitor-dialog
      v-if="showModal"
      @downloadReport="downloadReport"
      @closeDialog="closeDialog"
    />
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import HomeButton from "@/components/global/HomeButton";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapState, mapActions } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
import globalMixin from "@/services/mixins/global/global";
import helpOnlineMixin from "@/services/mixins/help_online/help-online-mixin";
import FileSelection from "@/components/global/FileSelection";
import ImportVisitorDialog from "@/components/visitor-management/ImportVisitorDialog";

export default {
  name: "ProfilePhoto",
  data() {
    return {
      helpSlug: "profile-photo",
      showModal: false,
    };
  },
  computed: {
    ...mapState("user", ["userData"]),
    ...mapState("visitorManagement", ["refreshVisitors"]),
  },
  methods: {
    ...mapActions("visitorManagement", ["setRefreshVisitors"]),
    closeDialog() {
      this.showModal = false;
      this.setRefreshVisitors(!this.refreshVisitors);
      this.$router.push({
        name: "r_visitor-management-reception-desk",
        params: { receptionDeskId: this.$route.params.receptionDeskId },
      });
    },
    uploadFile(event) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      const file = event.target.files[0];

      if (!file) {
        this.$store.commit("loader/setScreenLoading", false, { root: true });
        return;
      }
      if (file.type !== "text/csv") {
        this.$store.commit("loader/setScreenLoading", false, { root: true });
        return;
      }

      const formData = new FormData();
      formData.append("file", file);
      formData.append("reception_desk_id", this.$route.params.receptionDeskId);
      return httpServiceAuth
        .post(apiEndpoints.company.visitorsImport, formData, {
          headers: { "content-type": "multipart/form-data" },
        })
        .then(() => {
          this.showModal = true;
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    downloadExampleFile() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });

      return httpServiceAuth
        .get(`${apiEndpoints.company.visitorDownloadExample}`, {
          exposedHeaders: ["Content-Disposition"],
        })
        .then((response) => {
          let blob = new Blob(["\ufeff" + response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const contentDispositionHeader =
            response.headers["content-disposition"];
          let fileName = name;
          if (contentDispositionHeader) {
            const headerFileName = this.extractHeaderFileName(
              contentDispositionHeader
            );
            fileName = headerFileName ? headerFileName : fileName;
          }
          link.download = fileName;
          link.click();
          link.remove();
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
          this.declarationsModal = false;
        });
    },
    downloadReport() {
      this.$store.commit("loader/setScreenLoading", true, { root: true });

      const formData = new FormData();
      formData.append("reception_desk_id", this.$route.params.receptionDeskId);

      return httpServiceAuth
        .post(apiEndpoints.company.visitorsReport, formData, {
          exposedHeaders: ["Content-Disposition"],
        })
        .then((response) => {
          let blob = new Blob(["\ufeff" + response.data], {
            type: response.headers["content-type"],
          });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          const contentDispositionHeader =
            response.headers["content-disposition"];
          let fileName = name;
          if (contentDispositionHeader) {
            const headerFileName = this.extractHeaderFileName(
              contentDispositionHeader
            );
            fileName = headerFileName ? headerFileName : fileName;
          }
          link.download = fileName;
          link.click();
          link.remove();
        })
        .catch((error) => {
          if (error.response) {
            errorHandler(error.response);
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper,
    HomeButton,
    FileSelection,
    ImportVisitorDialog,
  },
  props: {
    backLinkName: {
      type: String,
    },
  },
  mixins: [globalMixin, helpOnlineMixin],
};
</script>

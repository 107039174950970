<template>
  <div class="status-dialog">
    <div class="dialog-wrapper">
      <div class="dialog-container">
        <!-- Status info -->
        <div class="dialog-body">
          <icon
            class="import-visitor-dialog-icon"
            icon="#cx-msg1-accepted-100x100"
          />

          <h2 class="import-visitor-dialog-title">
            {{ displayLabelName("visitor-management.import.success") }}
          </h2>

          <h3 @click="downloadReport" style="cursor: pointer;">
            {{ displayLabelName("visitor-management.import.download-report") }}
          </h3>
        </div>

        <div class="dialog-close" @click="closeDialog">
          <div class="dialog-action" style="width: 100%; border: none;">
            {{ displayLabelName("global.messages.ok") }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImportVisitorDialog",
  props: {},
  methods: {
    closeDialog() {
      this.$emit("closeDialog");
    },
    downloadReport() {
      this.$emit("downloadReport");
    },
  },
};
</script>
<style lang="scss">
.import-visitor-dialog-title {
  font-weight: bold;
  font-size: 17px;
  margin-bottom: 10px;
}
.import-visitor-dialog-icon {
  width: 100px;
  --color-3: #18a000 !important;
  fill: #18a000 !important;
}
.dialog-body svg {
  fill: #18a000 !important;
  --color-3: #18a000 !important;
}
</style>

<template>
  <div
    class="clebex-cropper-wrapper"
    style="width: 100%; height: 100%; cursor: pointer;"
  >
    <input
      :class="fileInputClass"
      ref="fileInput"
      type="file"
      accept=".csv"
      @input="pickFile"
      style="cursor: pointer;"
    />
    <div class="clebex-profile-photo-drag-drop-label">
      {{ displayLabelName("global.image-selection.drag-and-drop") }}
    </div>
  </div>
  <div class="clebex-photo-selection-type-wrapper">
    <div
      class="clebex-photo-selection-type"
      style="border-top-right-radius: 10px; border-top-left-radius: 10px; border-bottom: 1px solid gray;"
      @click="uploadFile"
    >
      <span>
        {{ displayLabelName("global.file-selection.upload-file") }}
      </span>
      <div>
        <icon
          style="width: 25px; transform: rotate(180deg); z-index: 1;"
          icon="#cx-men1-download-sample"
        />
      </div>
    </div>
    <div
      class="clebex-photo-selection-type"
      style="border-bottom-left-radius: 10px; border-bottom-right-radius: 10px;"
      @click="download()"
    >
      <span>
        {{ displayLabelName("global.file-selection.donwload-example") }}
      </span>
      <div>
        <icon
          style="width: 25px; z-index: 0;"
          icon="#cx-men1-download-sample"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FileSelection",
  data() {
    return {
      fileToUpload: null,
      showFileInput: false,
      mobileDevice: false,
    };
  },
  computed: {
    fileInputClass() {
      return `clebex-photo-file-input ${this.showFileInput ? "show" : ""}`;
    },
    store() {
      return this.storeInUse;
    },
  },
  mounted() {
    this.mobileDevice = this.isMobileDevice();
  },
  methods: {
    uploadFile() {
      this.$refs.fileInput.click();
    },
    pickFile() {
      this.upload(event);
    },
  },
  props: {
    upload: {
      type: Function,
      required: true,
    },
    download: {
      type: Function,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.svg-icon {
  width: 25px !important;
}
</style>
